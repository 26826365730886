'use client';
import { useMicrocopy } from '@lib/cms/microcopy/client';

import { Stars } from '@ui-components/index';
import { interpolateStringWithData } from 'utils';

type ProductRatingProps = {
  rating?: {
    average: number;
    count: number;
  };
};
export const ProductRating = ({ rating }: ProductRatingProps) => {
  const microcopy = useMicrocopy('product-card', {
    'product-card.star-rating-label': 'Rating: {{rating}} out of 5 stars',
    'product-card.star-count-label': 'Count: {{count}} ratings',
    'product-card.not-enough-ratings': '(Not enough ratings)',
  });

  if (!rating || rating.count === 0 || rating.average === 0) {
    return (
      <div className="tiny-text text-grey-400">
        {microcopy['product-card.not-enough-ratings']}
      </div>
    );
  }

  const roundedRating = Math.round(rating.average * 10) / 10;
  return (
    <div className="tiny-text lg:body-small -mt-0.5 flex items-center gap-1">
      <Stars
        value={roundedRating}
        width={16}
        ariaLabel={interpolateStringWithData(
          microcopy['product-card.star-rating-label'],
          {
            rating: roundedRating.toString(),
          }
        )}
      />
      <span data-test="product-rating">{roundedRating}</span>
      <div
        className="text-grey-600"
        data-test="product-rating-count"
        aria-label={interpolateStringWithData(
          microcopy['product-card.star-count-label'],
          {
            count: rating.count.toString(),
          }
        )}
      >
        ({rating.count})
      </div>
    </div>
  );
};
